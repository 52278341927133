@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");

$background: #282a36;
$darkBackground: darken($color: $background, $amount: 2);
$darkerBackground: darken($color: $background, $amount: 5);
$darkestBackground: darken($color: $background, $amount: 15);

$shadow: transparentize($color: $darkerBackground, $amount: 0.5);

$border: #44475a;
$text: #f8f8f2;

$white: #f8f8f2;
$black: #080808;

$cyan: #8be9fd;
$green: #50fa7b;
$darkGreen: darken($color: $green, $amount: 15);
$orange: #ffb86c;
$pink: #ff79c6;
$purple: #bd93f9;
$red: #ff5555;
$yellow: #f1fa8c;

$titleFont: "Roboto", sans-serif;
$bodyFont: "Open Sans", sans-serif;

$smallGap: 0.5rem;
$gap: 1rem;

@font-face {
  font-family: "caveat brush";
  src: url("../../assets/fonts/CaveatBrush-Regular.ttf") format("truetype");
}

html,
body,
dotio-layout {
  height: -webkit-fill-available;
}

body {
  margin: 0;
  padding: 0;
  background-color: $background;
  overflow-x: hidden;
  overflow-y: hidden;
  scroll-behavior: unset;
  z-index: 1;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
a:visited,
a:active,
ul,
li,
span {
  color: $darkBackground;
  font-family: $bodyFont;
  margin: 0;
}

table,
thead,
th,
tr,
td {
  color: $darkBackground;
}

label {
  color: $darkBackground;
}

/*****************************************************************************************
scrollbar styles
*****************************************************************************************/

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: lighten($color: $green, $amount: 25);
}

::-webkit-scrollbar-thumb {
  background: $green;
}

::-webkit-scrollbar-thumb:hover {
  background: darken($color: $green, $amount: 25);
}

/*****************************************************************************************
highlight styles
*****************************************************************************************/

::selection {
  background: $green;
  color: $background;
}